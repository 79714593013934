import Layout from "../../components/layout/Layout"
import React from 'react'

export default function Projects() {
  return (
    <Layout>
      <div>
        <h2>Projects</h2>
      </div>
    </Layout>
  )
}
